/* You can add global styles to this file, and also import other style files */
.form__title{
  box-sizing: inherit;
  font-family: Arial,sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
  color: #369;
}
.form__title_underline {
text-decoration: underline;
}
input {
  margin: 0px;
}
select {
  margin: 0px;
}
.tablepadding {
  width: 100%;
}
.tablepadding > tr > td:nth-child(even) {
  margin-left: 7px;
  white-space: nowrap;
  align-items: left;
}
.underline { 
  text-decoration: underline;
}
  
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip-country .tooltiptext-country {
  visibility: hidden;
  width: 220px;
  background-color: whitesmoke;
  border: 1px solid #ccc;
  color: #fff;
  text-align: center;
  border-radius: 6px;  padding: 15px;
  color: black;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip-country:hover .tooltiptext-country {
  visibility: visible;
}
.tooltip-country-col {
    display: flex;
    justify-content: right;
    flex-wrap: wrap;
    padding-top: 7px;
    position: absolute;
    top: 5px;
    right: 5px;
}

.tooltipinnerglobe {
  padding: 0px;
  margin: 0px;
  float: right;
}
.googletranslate {
  width: 50%;
}
#google_translate_element {
  position: fixed; 
  left: 5px; 
  bottom: 0px;
  z-index: 2147483647; /* TODO : should this always be on top */
}
.goog-te-combo {
  background: transparent;
  cursor: pointer;
}
.skiptranslate {
  transition: all 1s ease-out;
  top: unset!important;
  bottom: 0px!important;
}
.skiptranslate:hover {
  bottom: 7px!important;
}
.skiptranslate_slideup {
 bottom: -32px!important;
}
body{
  top: 0px!important;
}
#goog-gt-tt { display: none!important; }
.step_item {
  white-space: nowrap;
}
.spinnerDescriptionTitle.display > h2 {
  margin: auto;
}
.VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q { 
  background-color: unset!important; 
  box-shadow: unset!important;
}

.section-why-section .upsell-section-content {
  padding-bottom: 120px;
}

.section-upsell-why-box .upsell-section-content {
  padding-bottom: 120px;
} 

.skiptranslate > iframe {
  display: none;
}
.VIpgJd-ZVi9od-aZ2wEe-wOHMyf.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
  display:none;
}



/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fCRc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fABc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fCBc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fBxc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fCxc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fChc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu72xKKTU1Kvnz.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu5mxKKTU1Kvnz.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7mxKKTU1Kvnz.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4WxKKTU1Kvnz.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7WxKKTU1Kvnz.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7GxKKTU1Kvnz.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fCRc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fABc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fCBc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fBxc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fCxc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fChc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfCRc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfABc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfCBc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfBxc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfCxc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfChc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.headerWithMenu {
  background-color: #fff;
}
nav ul { margin: 0; }
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
*, *:before, *:after {
  box-sizing: inherit;
}

upsell-section.lightgrayBackground {
  background-color: #f2f4f9 !important;
}
.upsell-section-div { 
  background-color: inherit;
}
.wait_icon_hidden {
  display: none!important;
}

.orangeText{
  color: #ec7a23;
}
.headerWithMenu__col-right > * > * {
padding:0px;
margin: 0px;
}
.custom-col {
  line-height: 1;;
  float: right;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  margin: 0;
  color: grey;
  line-height: 1.2;
  max-width: 700px;
  max-width: 200px;
  margin: 6px 0;
  width: 100%;
  font-size:10px;
      margin-left: auto;
      margin-right: auto;
      padding: 0;
      float: none;

      .item-inner {
        padding: 0;

        .input-wrapper {
          padding: 0;


          .custom-label {
            margin-right: 0;
          }
        }
      }
}
.table_regularPrice {
  text-decoration: line-through;
}
.table_wrapper {
  width: 85%!important;
}
.table_wrapper > table {
  display: block;
  min-width: 160px;
}

.table_wrapper > table > tr {
  display: block;
  border-bottom: solid 1px #ccc;
  padding: 0px 0px 0px 15px;
  line-height: 1.0;;
}

.table_wrapper > table > tr > td {
  display: inline-block;
  min-width: fit-content;
  width: 140px;
  text-wrap: wrap;
  padding: 0px!important;
}

@media only screen and (max-width: 739px) {
  .step_item {
    white-space: wrap;
  }
  .clamp {
    font-size: clamp(0.95rem, 1.0vw, 1.0rem)!important;
  }
  .table_wrapper {
    width: 100%!important;
  }
  .table_coupon_wrapper {
    max-width: 100%; 
    text-wrap: nowrap;
  }
  .table_wrapper > table {
    display: block;
    min-width: 225px;
  }
  .table_wrapper > table > tr {
    padding-bottom: 5px;
  }
}
.table_wrapper > table {
  min-width: 225px;
}
.total_col {
  margin-right: 20px;
}
.table_savings > td {
  color: green!important;
}

a:hover {
    cursor: pointer;
}
body {
    margin: 0;
    height: 100%;
    padding-right: 2px;
    padding-left: 2px;
    overflow-x: auto;
    overflow-y: scroll;
    margin-bottom: 0;
    padding: 0 0%;
    width: 100%;
    clear: both;
    overflow-x: hidden;
    min-height: 120%;
}
.headerTextClass>p {
  font-weight: 600;
  font-size: 11px;
  color: #5F5454
}
.headerTextClass>h2 {
  font-weight: 300;
  font-size: 16px;
  color: #5F5454
}
.headerTextClass > p {
  padding-bottom:10px;
}
@media only screen and (min-width: 992px) {
  .headerWithMenu>.row {
    max-width: 700px;
    min-height: 80px;
  }
}
@media only screen and (max-width: 739px) {
  .headerWithMenu {
    min-height: 80px;
  }
  .form__row {
    max-width: 100%!important;
  }
  .billing_form_row{
    padding: 0 10px 40px 10px !important;
  }
  .headerTextClass>p {
    font-weight: 600;
    font-size: 8px;
    line-height: 1.5!important;
  }
  .headerTextClass>h2 {
    font-weight: 300;
    font-size: 13px;
    line-height: 1.5!important;
  }
}
.shell-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: #fff
}

.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -400px;
    margin-left: -400px
}

.spinnerProvider {
    display:none;
    box-shadow: 0 7px 25px #818181;
    padding: 10px 5px;
    width: max-content;
    height: max-content;
    max-width: 200px;
    max-height: 100px;
    position: fixed;
    top: 50%;
    left: 50%;
    background: white;
    transform: translate(-50%, -50%);
    min-width: 200px;
    margin: auto;
    z-index: 102;
}
.blue_link {
  color: blue;
  text-decoration: underline;
}
.hidden {
    visibility:hidden!important;
    display: none!important;
    opacity: 0!important;
    transition: visibility 0s 2s, opacity 2s linear;
}
.showing {
  visibility: visible;
  opacity: 0.5;
  display: inline-block;
}
.fadeIn {
  transition: visibility 0s 2s, opacity 2s linear;
  transition-delay: 3s;
  transition-duration: 3s;
  transition-property: opacity;
}
.spinnerProvider-cover {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 101;
    background-color: black;
    transition: visibility 0s 2s, opacity 2s linear;
}
body.noscroll
{
    overflow-y: auto;
}
.spinnerProvider-label {
    margin-top: 10px;
    margin-left: 50px;
    font-size: 14px;
    font-weight:500;
    min-height: 50px;
    color: gray;
    font-weight: 500;
    font-family: 'Figtree', sans-serif;
  }

.spinnerProvider-spinner {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border: 2px solid rgba(128, 128, 128, .3);
    border-left: 2px solid #19171c;
    border-radius: 50%;
    margin: 0 auto;
    -webkit-animation: loading-spinner 1750ms linear infinite;
    animation: loading-spinner 1750ms linear infinite;
    position: absolute;
    top: 25px;
    left: 15px;
    max-height: 60px;
}

@keyframes loading-spinner {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}


.shell-page {
    display: none;
}

.shell-page-mainbox {
    max-width: 400px;
    margin: auto;
    max-height: 300px;
    display: none;
    padding: 10px
}



/* The A16 container */
.container { 
    max-width: 100%!important;
    margin-left: 0px!important;
    margin-right: 0px!important;
    margin-top: 0px!important;
}

/* this is ionic inline directive for padding ported to A16 */

@media only screen and (max-width: 768px) {

  /* .upDownPage {
    margin-bottom: 200px!important;
  } */
  .form_item_label {
    padding-bottom: 6px;
  }
    [col-6] {
        font-size: inherit
    }
}

/* Ionic attribute styling */
[padding-horizontal] {
    padding: 5px;
}
[justify-content-center] {
    justify-content: center;
    /* max-width: 1000px; */
    margin: auto;
}
[text-uppercase]{
  text-transform: uppercase;
}
[padding]{
  padding: 16px;
}
[col-lg-4]{
  max-width: 33.33%;
  width: 33.33%;
}

[col-md-4]{
  max-width: 33.33%;
  width: 33.33%;
}
[col-md-8]{
  max-width: 66.66%;
  width: 66.66%;
}
[no-padding] {
  padding: 0;
}

[padding-top] {
  padding-top: 16px;
}
[col-md-6], [col-sm-6], [col-xs-6]{
  max-width: 50%;
  width: 50%;
}
[padding-horizontal] {
  padding-left: 16px;
  padding-right: 16px;
}
.upDownPage {
  margin: auto;
}

/*  general styles */
.hidden-mobile {
  display: block;
}
.hidden-desktop {
  display: none;
}
@media only screen and (max-width:1024px){
  .hidden-mobile {
    display: none;
  }
  .hidden-desktop {
    display: block;
  }
}


.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    width: 100%;
    padding-right: 6px;
    padding-left: 0px;
}
.payment_tab_col {
    justify-content: left;
    max-width: 121px;
    font-weight: bold;
    position: sticky;
}
.bold{
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  html {
    scrollbar-color: #2c507c white; 
  }
}

/* custom browser scroll bar applied globally */
html::-webkit-scrollbar {
  width:8px;
  max-width: 12px;
  background-color: whitesmoke;
}

html::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #2c507c;  
}

html::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb:window-inactive {
  background: #2c507c;
}

.custom-label {
  text-rendering: optimizelegibility;
  overflow-wrap: break-word;
  text-size-adjust: none;
  -webkit-font-smoothing: auto;
  text-align: initial;
  line-height: normal;
  text-transform: none;
  user-select: text;
  -webkit-box-direction: inherit;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1 1 0%;
  white-space: nowrap;
  font-weight: 300;
  /* font-family: proxima_nova_regular, sans-serif; */
  font-family: HelveticaNeue-Light, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;

  font-size: 17px;
  letter-spacing: 0.82px;
  margin-right: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(93, 97, 98);
  column-gap: 7px;
  padding-right: 10px;
}

/* header-with-menu.scss */
header-with-menu {
    .headerWithMenu {
      margin: auto;
  

      .cart-quantity {
        display: none;
      }
  
      & > .row {
        width: 100%;
        margin: 12px auto 0;

        .headerWithMenu__col-left{
            padding:0;
            margin:0;
            display: inline-block;
            max-width: 49%;
        }
        .headerWithMenu__col-right{
            padding:0;
            padding-left: 15px;
            margin:0;
            text-align: right;
            float: right;
            flex: 0 0 50%;
            max-width: 50%;
            div{
             margin-left: auto; 
            }
        }
 
  
        .headerWithMenu__menu {
          .headerWithMenu__item.item-block {
            width: fit-content;
  
            .custom-label {
              color: #5d6162;
              font-size: 17px;
              font-weight: 300;
              font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
            }
  
            .custom-thumbnail {
              min-width: initial;
              min-height: initial;
              margin: 0;
  
              :is(img) {
                width: initial;
                height: initial;
              }
            }
  
            .item-inner {
              border: none;
            }
  
            .custom-icon {
              margin: 15px;
              font-size: 32px;
              color: #b8d436;
            }
          }
  
          &:is(--inline) {
            & > .custom-list {
              margin: 0;
  
              & > .item, & > .headerWithMenu__dropDown {
                display: inline-flex;
                width: fit-content;
                max-width: fit-content;
              }
  
              & > .item, & > .headerWithMenu__dropDown > .item {
                min-height: unset;
  
                .custom-label {
                  margin: 5px 0;
                  font-size: 15px;
                  font-weight: 600;
                  color: #5e5f61;
                  font-family: proxima_nova_regular;
                }
              }
  
              & > .headerWithMenu__dropDown {
                position: relative;
  
                & > .custom-list {
                  width: auto;
                  right: -25%;
  
                  .item {
                    .custom-label {
                      font-weight: 100;
                      font-size: 14px;
                    }
                  }
  
                  .custom-item:hover {
                    background-color: #d7d7d7;
                    color: #b9d537;
                  }
  
                  .custom-item.activated {
                    background-color: #4A4A4A;
                  }
                }
              }
            }
          }
        }
  
        :not(.headerWithMenu__menu--inline) {
          & > .custom-list {
            position: absolute;
            width: 250px;
            box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
            z-index: 99999;
            right: 0;
            top: 100%;
  
            .custom-item:hover {
              background-color: #d7d7d7;
            }
  
            .custom-item.activated {
              background-color: #4A4A4A;
            }
          }
        }
  
        .headerWithMenu__menu, .headerWithMenu__menu--inline {
          .custom-list {
            .item {
              .item-inner {
                border: none;
              }
            }
          }
        }
  

  
    
      }
    }

  
    @media only screen and (min-width: 992px) {
      .headerWithMenu {
        & > .row {
          max-width: 700px;
        }
  
        .headerWithMenu__menu, .headerWithMenu__menu--inline {
          .custom-list[hidden] {
            display: initial;
          }
        }
  
        .headerWithMenu__menu {
          .custom-list {
            .item {
              color: #233770;
              font-size: 16px;
              font-weight: 700;
              font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
            }
          }
        }
  
        .headerWithMenu__hidden {
          display: none;
        }
      }
    }
  
    @media only screen and (max-width: 991px) {
      
      .form_item_label {
        padding-bottom: 6px !important;
      }

      .headerWithMenu {
        & > .row {
          max-width: calc(100% - 40px);
        }
  
        .headerWithMenu__menu {
          .custom-list {
            .item {
              color: #092c74;
              font-size: 18px;
              font-family: 'Helvetica', Arial, sans-serif;
            }
          }
        }
  
        .headerWithMenu__hiddenOnMobile {
          display: none;
        }
      }
    }
  
    @media only screen and (min-width: 768px) {
      .headerWithMenu {
        & > .row {
          .custom-col[text-md-right][text-center] {
            .headerWithMenu__item--align {
              float: right;
            }
          }
        }
      }
    }
}
.header-with-menu_after {
  width: 100%;
  height: 18px;
  box-shadow: 0 8px 10px -4px #949494;
}

/* page specific */
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}



#country {
  min-width: 200px;
  color: black;
}
#state {
  min-width: 200px;
  color: black;
}
#expYear, #expMonth, #typeOfCard{
  color: black;
}
dialog {
  height: 80%;
  width: 50%;
}

#vanillatoasts-container {
  position: fixed;
  width: 700px;
  font-family: 'Montserrat', sans-serif;
  pointer-events: none;
  z-index: 222;
  color: white;
  font-weight: bold;
  z-index: 10000001;
}
#vanillatoasts-container button{
  font-size: 15px;
  text-transform: uppercase !important;
}
.toasts-top-right{
  top: 0;
  right: 100%;
}
.toasts-top-left{
  left:0;
  top:0;
}
.toasts-bottom-left{
  left: 0;
  bottom:0;
}
.toasts-bottom-right{
  bottom:0;
  right: 0;
}
.toasts-top-center{
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
.toasts-bottom-center{
  bottom:0;
  left: 50%;
  transform: translateX(-50%);
}
.vanillatoasts-toast {
  position: relative;
  padding: 20px 17px;
  margin: 20px;
  border-radius: 0px;
  background: #333333;
  cursor: pointer;
  animation-duration:  .8s;
  animation-name: VanillaToasts;
  animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  pointer-events: all; 
  margin-top: 0px;
}

@media only screen and (max-width: 700px) {
  #vanillatoasts-container{
    width: 100%;
    max-width: 100%;
  }
  .vanillatoasts-toast {
    margin: 0;
  }
  #vanillatoasts-container p{
    display: flex;
    width: 40%;
  }
  #vanillatoasts-container p.vanillatoasts-text{
    width: 60%;
  }
  #vanillatoasts-container button{
    margin: auto;
  }
}
@media only screen and (max-width: 450px) {
  header-with-menu {
    .headerWithMenu {
      & > .row {
        width: 100%;
        margin: 1px auto 0;
      }
    }
  }
  #vanillatoasts-container p{
    width: 50%;
  }
  #vanillatoasts-container p{
    display: flex;
    width: 20%;
  }
  #vanillatoasts-container p.vanillatoasts-text{
    width: 80%;
  }
  #vanillatoasts-container button{
    margin-top: 10px;
  }
}

.vanillatoasts-fadeOut {
  animation-name: VanillaToastsFadeOut;
  animation-duration: .8s;
  animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  animation-fill-mode: forwards;
}

#vanillatoasts-container p,
#vanillatoasts-container h4 {
  margin: 3px 0!important;
}

.vanillatoasts-title {
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 10px;
}

.vanillatoasts-text {
  font-size: 14px;
  color: white;
  padding-right: 75px;
}

.vanillatoasts-icon {
  position: absolute;
  top: 5px;
  left: -40px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  background: #FFF;
}

.vanillatoasts-toast a, .vanillatoasts-toast a:hover {
  color: #549EDB !important;
  text-decoration: none !important;
}

/** toast types */
.vanillatoasts-success {
  border-bottom: 2px solid #51C625;
  background-color: green;
}

.vanillatoasts-warning {
  border-bottom: 2px solid #DB9215;
}

.vanillatoasts-error {
  border-bottom: 2px solid #DB2B1D;
}

.vanillatoasts-info {
  border-bottom: 1px solid #333333;
}

@keyframes VanillaToasts {
  from {
    transform: translate3d(0, -120px, 0);;
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes VanillaToastsFadeOut {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(0, -120px, 0);
    opacity: 0;
  }
}

.vanillatoasts-danger {
  /*
  $tcr-colors: (
  primary:              #292823,
  secondary:            #f18a48,
  danger:               #f53d3d,
  light:                #f4f4f4,
  dark:                 #222,

  page-bg:              #f6f6f6,
  menu-bg:              #f7f7f7,
  main-bg:              #efefef,
  white-bg:             #ffffff,

  status-available:     #008000,
  status-fail:          #800000,
  status-pending:       #000080,
  status-not-available: #000000,

  text-main:            #7f7f7f,
  text-dark:            #4a4a4a,
  text-light:           #b0b0b0,
  text-header:          #4993C2,

  favorite:       #69BB7B,
  twitter:        #1da1f4,
  google:         #dc4a38,
  vimeo:          #23b6ea,
  facebook:       #3b5998,
);
  */
  background: #800000;
}
[text-center]{
  text-align: center;
}

@font-face {
  font-family: 'Figtree';
  src: url('https://cdn.gundrymd.com/fonts/Figtree-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Figtree';
  src: url('https://cdn.gundrymd.com/fonts/Figtree-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Figtree';
  src: url('https://cdn.gundrymd.com/fonts/Figtree-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Figtree';
  src: url('https://cdn.gundrymd.com/fonts/Figtree-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree';
  src: url('https://cdn.gundrymd.com/fonts/Figtree-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Figtree';
  src: url('https://cdn.gundrymd.com/fonts/Figtree-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Figtree';
  src: url('https://cdn.gundrymd.com/fonts/Figtree-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Figtree';
  src: url('https://cdn.gundrymd.com/fonts/Figtree-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Figtree';
  src: url('https://cdn.gundrymd.com/fonts/Figtree-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Figtree';
  src: url('https://cdn.gundrymd.com/fonts/Figtree-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Figtree';
  src: url('https://cdn.gundrymd.com/fonts/Figtree-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Figtree';
  src: url('https://cdn.gundrymd.com/fonts/Figtree-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Figtree';
  src: url('https://cdn.gundrymd.com/fonts/Figtree-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Figtree';
  src: url('https://cdn.gundrymd.com/fonts/Figtree-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

/*
*   MONTSERRAT
*/
@font-face {
  font-family: 'Montserrat';
  font-weight: 100;
  font-display: swap;
  font-style: normal;
  src: local('Montserrat-Thin'),
  url('https://cdn.gundrymd.com/fonts/montserrat/Montserrat-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 100;
  font-display: swap;
  font-style: italic;
  src: local('Montserrat-ThinItalic'),
  url('https://cdn.gundrymd.com/fonts/montserrat/Montserrat-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 200;
  font-display: swap;
  font-style: normal;
  src: local('Montserrat-ExtraLight'),
  url('https://cdn.gundrymd.com/fonts/montserrat/Montserrat-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 200;
  font-display: swap;
  font-style: italic;
  src: local('Montserrat-ExtraLightItalic'),
  url('https://cdn.gundrymd.com/fonts/montserrat/Montserrat-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  font-display: swap;
  font-style: normal;
  src: local('Montserrat-Light'),
  url('https://cdn.gundrymd.com/fonts/montserrat/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  font-display: swap;
  font-style: italic;
  src: local('Montserrat-LightItalic'),
  url('https://cdn.gundrymd.com/fonts/montserrat/Montserrat-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-display: swap;
  font-style: normal;
  src: local('Montserrat-Regular'),
  url('https://cdn.gundrymd.com/fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-display: swap;
  font-style: italic;
  src: local('Montserrat-Italic'),
  url('https://cdn.gundrymd.com/fonts/montserrat/Montserrat-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  font-display: swap;
  font-style: normal;
  src: local('Montserrat-Medium'),
  url('https://cdn.gundrymd.com/fonts/montserrat/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  font-display: swap;
  font-style: italic;
  src: local('Montserrat-MediumItalic'),
  url('https://cdn.gundrymd.com/fonts/montserrat/Montserrat-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  font-display: swap;
  font-style: normal;
  src: local('Montserrat-SemiBold'),
  url('https://cdn.gundrymd.com/fonts/montserrat/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  font-display: swap;
  font-style: italic;
  src: local('Montserrat-SemiBoldItalic'),
  url('https://cdn.gundrymd.com/fonts/montserrat/Montserrat-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  font-display: swap;
  font-style: normal;
  src: local('Montserrat-Bold'),
  url('https://cdn.gundrymd.com/fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  font-display: swap;
  font-style: italic;
  src: local('Montserrat-BoldItalic'),
  url('https://cdn.gundrymd.com/fonts/montserrat/Montserrat-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  font-display: swap;
  font-style: normal;
  src: local('Montserrat-ExtraBold'),
  url('https://cdn.gundrymd.com/fonts/montserrat/Montserrat-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  font-display: swap;
  font-style: italic;
  src: local('Montserrat-ExtraBoldItalic'),
  url('https://cdn.gundrymd.com/fonts/montserrat/Montserrat-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 900;
  font-display: swap;
  font-style: normal;
  src: local('Montserrat-Black'),
  url('https://cdn.gundrymd.com/fonts/montserrat/Montserrat-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 900;
  font-display: swap;
  font-style: italic;
  src: local('Montserrat-BlackItalic'),
  url('https://cdn.gundrymd.com/fonts/montserrat/Montserrat-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-display: swap;
  font-style: normal;
  src: local('Montserrat-Regular'),
  local('Montserrat-regular'),
  url('https://cdn.gundrymd.com/fonts/Montserrat-regular/Montserrat-regular.woff2') format('woff2'),
  url('https://cdn.gundrymd.com/fonts/Montserrat-regular.woff') format('woff'),
  url('https://cdn.gundrymd.com/fonts/Montserrat-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  font-display: swap;
  font-style: normal;
  src: local('Montserrat-Bold'),
  local('Montserrat-700'),
  url('https://cdn.gundrymd.com/fonts/Montserrat-700/Montserrat-700.woff2') format('woff2'),
  url('https://cdn.gundrymd.com/fonts/Montserrat-700/Montserrat-700.woff') format('woff'),
  url('https://cdn.gundrymd.com/fonts/Montserrat-700/Montserrat-700.ttf') format('truetype');
}

.upsellDownsell {
  /*TODO: REMOVE*/
  /* margin-bottom:140px; */
}
.shell-page__shinner-label{
  font-family: 'Figtree';
}
@media (min-width: 1025px){
  [col-xl-4]{

    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  [col-xl-8]{
    flex: 0 0 66.6666666667%;
    width: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  [col-md-10], [col-lg-10]{
    flex: 0 0 83.33333%;
    width: 83.33333%;
    max-width: 83.33333%;
  }
  [col-md-2], [col-lg-2]{
    flex: 0 0 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%;
  }

  [col-lg-6]{
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
  }
  [text-md-left]{
    text-align: left;
  }
  [col-lg-8] {
    flex: 0 0 66.6666666667%;
    width: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  [col-lg-4] {
    flex: 0 0 33.3333333333%;
    width: 33.3333333333%;
    max-width: 33.3333333333%;
}
}

@media screen and (max-width: 1024px) {
  [col-12]{
    max-width: 100%;
    width: 100%;
  }
}

.m-auto{
  margin: auto;
}
[no-margin]{
  margin: 0 !important;
}
[margin-bottom]{
  margin-bottom: 16px;
}

.table_coupon_wrapper > .table_wrapper > table > tr {
  white-space: nowrap;
  display: flex;
  justify-content: flex-end;
}




/* Braintree */

[data-braintree-id="discover-card-icon"] {
  display: none !important;
}

[data-braintree-id="unionpay-card-icon"] {
  display: none !important;
}

[data-braintree-id="jcb-card-icon"] {
  display: none !important;
}

.braintreeloader {
  margin-left: 40%;
  border: 8px solid #edc655;
  border-radius: 50%;
  border-top: 8px solid #609ad9;
  width: 20px;
  height: 20px;
  animation: braintreespinner 4s linear infinite;
  position: relative;
  top: 35px;
}

.loader {
  margin-left: 40%;
  border: 10px solid #edc655;
  border-radius: 50%;
  border-top: 10px solid #609ad9;
  width: 10px;
  height: 10px;
  -webkit-animation: spinner 4s linear infinite;
  animation: spinner 4s linear infinite;
  position: relative;
  top: -20px;
  right: 50px;
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.braintreesticky {
  position: sticky !important;
  margin: auto !important;
}

.braintreecard {
  border: 16px solid #161d60 !important;
  border-top: 16px solid #4c70d9 !important;
}

.braintree-sheet {
  border-bottom: none;
}

.braintree-sheet__header {
  display: none;
}

.braintree-placeholder {
  margin-bottom: 0px;
}

.formBT {
  margin-top: 5px;
  margin-right: 10px;
  border: 1px solid lightgray;
}

.braintree-card {
  border: none;
}

.payment_form_col {
  padding-top: 0px;
}


.braintree-payment-form {
  float: left;
  border-bottom: none;
  text-rendering: optimizelegibility;
  overflow-wrap: break-word;
  text-size-adjust: none;
  -webkit-font-smoothing: auto;
  color: rgb(0, 0, 0);
  -webkit-box-direction: normal;
  user-select: text;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  min-height: 1px;
  max-width: 100%;
  margin: 0px;
  -webkit-box-flex: 0;
  width: auto;
  flex: 0 0 auto;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-image: linear-gradient(rgb(242, 244, 241), rgb(255, 255, 255) 30%);
  box-shadow: rgb(255, 255, 255) 0px 1px 0px 0px inset;
  padding: 5px 10px;
  border-width: 1px 1px 1px;
  border-style: solid solid none;
  border-color: rgb(205, 207, 204) rgb(205, 207, 204) rgb(205, 207, 204);
  border-image: initial;
  border-bottom: none;
}

.btpayment_form label {
  box-sizing: inherit;
  order: 1;
  width: 115px;
  font-family: Arial,sans-serif;
  font-size: 12px;
  color: #000;
  text-align: right;
  font-weight: 700;
  margin-right: 10px;
  position: absolute;
  padding-top:10px;
}
.form_item_elements {
  padding-top: 5px;
}

.btfirstrow {
  margin-top: 10px;
}
.item_info_bt {
  position: relative;
  left: 200px;
  top: -18px;
  font-size:12px;
  clear: both;
  display: block;
  height: 0px;
}
.item_link_bt {
  position: relative;
  left: 200px;
  top: -22px;
  font-size:12px;
}
.item_link_bt_dr {
  margin-left:300px;
  
}
.btpayment_form select {
  box-sizing: inherit;
  margin: 0px;
  order: 2;
  display: block;
  border: 1px solid #666666;
  border-right-color: #ccc;
  border-bottom-color: #ccc;
  min-height: 22px;
  font-family: Arial,sans-serif;
  font-size: 12px;
  background-color: #ddd;
  white-space: nowrap;
  border-radius: 0;
  width: auto;
  color: black;
  margin-left: 120px;
}
.braintree-card-row {
  margin-top: -1px;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 0px;
  padding-right: 0px;
}

.braintree-placeholder {
  height: 0px;
}

.braintree-form__hosted-field {
  height: 24px !important;
  border-top: 1px solid #666666 !important;
  border-left: 1px solid #666666 !important;
  border-bottom: 1px solid #cccccc !important;
  border-right: 1px solid #cccccc !important;
  margin-top: -10px !important;
  padding-left: 1px !important;
}
.braintree-form__label {
  font-family: Arial, sans-serif !important;
  font-size: 12px !important;
  color: #000000 !important;
  font-weight: 700 !important;
  padding-top: 10px !important;
  min-width: 110px !important;
  color: white !important;
}

[data-braintree-id="cardholder-name-field-group"]::after {
  content: "Name on Card\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
  font-size: 12px;
  font-weight: 700;
  position: relative;
  top: -30px;
  left: 10px;
  background: white;
  margin-bottom: 0px !important;
}

[data-braintree-id="number-field-group"]::after {
  content: "Card Number";
  font-size: 12px;
  font-weight: 700;
  position: relative;
  top: -30px;
  left: 10px;
  background: white;
  margin-bottom: 0px !important;
}

[data-braintree-id="expiration-date-field-group"]::after {
  content: "Expiration Date";
  font-size: 12px;
  font-weight: 700;
  position: relative;
  top: -30px;
  left: 10px;
  background: white;
  margin-bottom: 0px !important;
}

[data-braintree-id="cvv-field-group"]::after {
  content: "CVV2";
  font-size: 12px;
  font-weight: 700;
  position: relative;
  top: -30px;
  left: 10px;
  background: white;
}

.braintree-form__field-error {
  position: absolute;
  font-size: 11px !important;
  margin: 0px !important;
  display: none !important;
}
.braintree-upper-container {
  line-height: 20px;
}

.braintreelink {
  position: relative;
  font-size: 9px;
  bottom: 25px;
  text-decoration: underline;
  z-index: 2;
  color: #537ea9;
  margin-top: -13px;
}

.braintree-form__notice-of-collection {
  display: none;
}

.braintree-sheet__content {
  padding: 0px;
  padding-bottom: 10p;
}

.inputBT {
  box-sizing: inherit;
  margin: 0px;
  order: 2;
  display: block;
  border-radius: 0!important;
  width: 100%;
  min-height: 20px;
  max-width: 200px;
  border-top: 1px solid #666666;
  border-left: 1px solid #666666;
  border-bottom: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  font-family: Arial,sans-serif;
  font-size: 14px;
  box-shadow: none!important;
  margin-left: 120px;
}


@media only screen and (min-width: 768px) {

[data-braintree-id="cardholder-name-field-group"] {
  max-width: 375px;
  margin-bottom: -10px !important;
}

[data-braintree-id="number-field-group"] {
  max-width: 375px;
  margin-bottom: -10px !important;
}

[data-braintree-id="expiration-date-field-group"] {
  max-width: 225px;
  margin-bottom: -10px !important;
}

[data-braintree-id="cvv-field-group"] {
  max-width: 225px;
  margin-bottom: -10px !important;
}

.braintree-form__flexible-fields {
  width: max-content;
}

.braintree-form-cardholder-name {
  height: 30px;
}

.braintree-form-number {
  height: 30px;
}

.braintree-form-expiration {
  height: 30px;
}

.braintree-form-cvv {
  height: 30px;
}

.braintree-form__field {
  padding-bottom: 0px !important;
}

.braintree-form__field-group {
  padding-bottom: 0px;
  font-size: 12px !important;
  font-weight: 700 !important;
  font-family: Arial, sans-serif !important;
  margin-bottom: -15px !important;
}

[data-braintree-id="cvv-icon"] {
  transform: scale(0.75) !important;
  margin-top: 1px !important;
}

[data-braintree-id="card-number-icon"] {
  transform: scale(0.75) !important;
  margin-top: 0px !important;
}

[data-braintree-id="card"] {
  background-image: linear-gradient(to top, #f6f7f5, #fff 20%);
}



.braintree-form__field-group label {
  display: flex;
  justify-content: space-between;
  margin: 0px 10px;
}

[data-braintree-id="number-field-error"] {
  padding-left: 120px !important;
}

[data-braintree-id="cardholder-name-field-error"] {
  padding-left: 120px !important;
}

[data-braintree-id="expiration-date-field-error"] {
  padding-left: 120px !important;
  width: max-content;
}

[data-braintree-id="cvv-field-error"] {
  padding-left: 120px !important;
  width: max-content;
}

[data-braintree-id="sheet-container"] {
  margin-bottom: 0px !important;
}

.braintree-form__flexible-fields {
  display: contents !important;
}

.braintree-form__icon-container {
  margin-right: -10px !important;
  margin-top: 1px !important;
  top: 3px !important;
  margin-top: -14px !important;
}

.braintree-form__descriptor {
  font-size: 10px !important;
  margin-left: 0px !important;
  margin-top: -14px !important;
}

.braintree-form__descriptor:first-child {
  position: absolute;
  left: 225px;
}


}

@keyframes braintreespinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
@-moz-document url-prefix() {
  .paypal-buttons {
    opacity: 1!important;
  }
}


.inputBT {
  height:22px;
  color: #495057;
  background-color: #fff;
  border: 1px solid #666666;
  border-right-color: #cccccc;
  border-bottom-color: #cccccc;
  width: 100%;
}
.formBT {
  width: 100%;
}
#expirationDateBT {
  max-width: 65px;
}
#cvcCodeBT {
  max-width: 65px;
}
.braintree-hosted-fields-focused {
  color: #495057;
  background-color: #fff;
  border-color: black;
  outline: 0;
  box-shadow: 0 0 0 1px black;
}
.braintree-hosted-fields-focused.is-invalid {
  border-color: #dc3545;
  box-shadow: 0 0 0 1px black;
}

#cardNumberBTerror, #nameOnCardBTerror {
  left: 320px;
  position: relative;
  top: -21px;
  width: 40px;
  display: flex;
  height: 20px;
}
#expirationDateBTerror, #cvcCodeBTerror {
  left: 220px;
  position: relative;
  top: -21px;
  width: 40px;
  display: flex;
  height: 20px;
}

.btlastrow {
  margin-bottom: 20px;
}
.item_link_bt {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .btelements {
    height: 70px;
  }
  .btlastrow {
    margin-bottom: 15px;
  }
  #cardNumberBTerror, #nameOnCardBTerror {
    left: 85%;
    height: 25px;
    top: -25px;
  }
  #expirationDateBTerror, #cvcCodeBTerror {
    left: 85%;
    position: relative;
    top: -25px;
    width: 40px;
    display: flex;
    height: 25px;
  }
  .btpayment_form label {
    font-size: 17px;
    display: flex;
    width: fit-content;
  }
  .btpayment_form select {
    margin-left: 0px;
    margin-top:25px; 
    font-size: 17px;
    width: 95%;;
    height: 30px;
  }
  .formBT {
    padding-left:10px;
  }
  .inputBT {
    margin-left: 0px;
    margin-top: 25px;
    min-width: 95%;
  }
  #expirationDateBT {
    max-width: 85px;
  }
  #cvcCodeBT {
    max-width: 85px;
  }
  .inputBT {
    height:30px;
    color: #495057;
    background-color: #fff;
    border: 1px solid #666666;
    border-right-color: #cccccc;
    border-bottom-color: #cccccc;
    width: 100%;
  }
  .item_info_bt {
    position: relative;
    left: 0px;
    top: 2px;
  }
  .item_link_bt {
    position: relative;
    left: 0px;
    top: 2px;
    cursor: pointer;
    text-decoration: underline;

  }
  .btlastrow {
    margin-top: 2px;
    padding-bottom: 10px;
  }

  .loader {
    display: none;
  }
  .braintree-form__field-group {
    margin-bottom: 0px !important;
  }

  [data-braintree-id="cardholder-name-field-group"]::after {
    top:  -57px;;
    left: 0px;
    font-size: 22px;
  }

  [data-braintree-id="number-field-group"]::after {
    top:  -57px;;
    left: 0px;
    font-size: 22px;
  }

  [data-braintree-id="expiration-date-field-group"]::after {
    top:  -57px;;
    left: 0px;
    font-size: 22px;
  }

  [data-braintree-id="cvv-field-group"]::after {
    top:  -57px;;
    left: 0px;
    font-size: 22px;
  }
  .braintree-form__hosted-field {
    height: 32px !important;
  }
  .braintree-form__descriptor {
    position: relative;
    top: -10px;
    display: inline-block;
    left: 20px;
    bottom: 65px;
    margin-left: 18px!important;
  }

  .braintreelink {
    left: 12px;
  }

}